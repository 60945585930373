import { render, staticRenderFns } from "./item-title.vue?vue&type=template&id=fd632b12&scoped=true&"
import script from "./item-title.vue?vue&type=script&lang=js&"
export * from "./item-title.vue?vue&type=script&lang=js&"
import style0 from "./item-title.vue?vue&type=style&index=0&id=fd632b12&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd632b12",
  null
  
)

export default component.exports