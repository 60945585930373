<template>
  <div class="home_box">
    <!-- 轮播图 -->
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item
        v-for="(item, i) in img_src"
        :key="i"
        :style="`background:url(${item.src})no-repeat center / cover`"
        :class="{ right: i == 2 }"
      >
        <!-- <img :src="item.src" /> -->
      </van-swipe-item>
    </van-swipe>
    <!-- 溯源模块 -->
    <div class="traceability">
      <!-- 标题部分公共组件 -->
      <item-title :title_data="traceability_title"></item-title>
      <!-- 溯源主体内容 -->
      <traceability-item></traceability-item>
    </div>

    <!-- 产品中心模块 -->
    <div class="product">
      <!-- 标题部分公共组件 -->
      <item-title :title_data="product_title"></item-title>
      <!-- 产品中心主体内容 -->
      <system-item :system_data="product_list"></system-item>
    </div>
    <!-- 行业解决方案模块 -->
    <div class="applications">
      <!-- 标题部分公共组件 -->
      <item-title :title_data="applications_title"></item-title>
      <!-- 行业解决方案主体 -->
      <application-item :application_list="application_list"></application-item>
    </div>
  </div>
</template>

<script>
import ItemTitle from "@/components/item-title.vue";
import TraceabilityItem from "./components/traceability_item.vue";
import SystemItem from "./components/system_item.vue";
import ApplicationItem from "./components/application_item.vue";
export default {
  name: "home",
  props: {
    //产品中心导航数据
    product_nav: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      // 轮播图路径
      img_src: [
        { src: require("@/assets/img/swiper/swiper_1.jpg") },
        { src: require("@/assets/img/swiper/swiper_2.jpg") },
        { src: require("@/assets/img/swiper/swiper_3.jpg") },
      ],
      // 溯源标题数据
      traceability_title: {},
      // 产品中心标题数据
      product_title: {},
      // 产品中心 系统介绍数据
      product_list: [],
      //行业解决方案标题数据
      applications_title: {},
      // 解决方案 介绍数据
      application_list: [],
    };
  },
  components: {
    ItemTitle,
    TraceabilityItem,
    SystemItem,
    ApplicationItem,
  },
  methods: {
    load_title_data() {
      const { home } = require("@/assets/data/data.json");
      this.traceability_title = home.traceability_title;
      this.product_title = home.product_title;
      this.product_list = this.$parent.product_nav;
      this.applications_title = home.applications_title;
      this.application_list = this.$parent.application_nav;
    },
  },
  created() {
    this.load_title_data();
  },
};
</script>

<style lang="less" scoped>
.home_box {
  width: 100%;
  .my-swipe {
    height: 400px;

    .van-swipe-item {
      img {
        width: 100%;
        min-height: 100%;
      }
    }
  }
  // .traceability {
  //   margin-top: 50px;
  // }
  .product {
    margin-top: 50px;
    background-color: #e8e8e8;
    padding: 1px 0;
  }
  .right {
    background-position: right !important;
  }
}
</style>