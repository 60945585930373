<template>
  <div>
    <div class="application_body">
      <div
        class="item"
        v-for="(item, i) in application_list"
        :key="i"
        @click="gotopage(item)"
      >
        <i class="iconfont" :class="item.icon"></i>
        <h3>{{ item.title_1 }}</h3>
        <p>{{ item.title_2 }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    application_list: {
      type: Array,
      required: true,
    },
  },
  methods: {
    gotopage(itme) {
      this.$router.push({
        path: itme.router_path,
        query: {
          title: itme.banner_title,
          desc: itme.banner_desc,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.application_body {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 30px;
  margin-bottom: 80px;

  .item {
    width: 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding: 30px 0;
    // border: 1px solid #e0e0e0;

    i {
      font-size: 110px;
      line-height: 1.3;
      color: #4fbdf6;
    }

    h3 {
      color: #1d1d1f;
      font-size: 35px;
    }
    p {
      font-size: 20px;
      color: #1d1d1f;

      text-transform: uppercase;
    }
  }
}
</style>