<template>
  <div>
    <div class="system">
      <div
        class="system_item"
        v-for="(item, i) in system_data"
        :key="i"
        @click="gotopage(item)"
      >
        <!-- @click="
          $router.push({
            path: '/productcenter/autotagging',
            query: {
              title: '生产线赋码系统',
              desc: 'Automatic code assignment',
            },
          })
        " -->
        <div class="left_img">
          <img :src="require('@/assets/img/product/' + item.img_src)" />
        </div>
        <div class="right_info">
          <h3>{{ item.system_title }}</h3>
          <p>
            {{ item.system_info }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Systemitem",
  props: {
    system_data: {
      type: Array,
      required: true,
    },
  },
  methods: {
    gotopage(itme) {
      this.$router.push({
        path: itme.router_path,
        query: {
          title: itme.banner_title,
          desc: itme.banner_desc,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.system {
  width: 100%;
  padding: 0 30px;
  margin-bottom: 80px;
  .system_item {
    width: 100%;
    display: flex;
    margin-bottom: 40px;

    .left_img {
      width: 30%;
      img {
        width: 100%;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
    }
    .right_info {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;
      border: 3px solid #fff;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      width: 70%;
      h3 {
        font-size: 35px;
        color: #312974;
      }
      p {
        font-size: 27px;
        padding: 0 18px;
        color: #1d1d1f;
        text-align: center;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        word-break: break-all;
      }
    }
  }
}
</style>