<template>
  <div>
    <div class="head">
      <div class="title">
        <h2>{{ title_data.title_1 }}</h2>
        <h3>{{ title_data.title_2 }}</h3>
      </div>
      <p class="title_info" v-if="title_data.title_desc">
        {{ title_data.title_desc }}
      </p>
    </div>
    <van-divider
      :style="{
        color: '#1d1d1f',
        borderColor: '#1d1d1f',
      }"
    >
      <i class="iconfont icon_line" :class="title_data.icon"></i>
    </van-divider>
  </div>
</template>

<script>
export default {
  name: "item-title",
  props: {
    // 标题数据
    title_data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="less" scoped>
.head {
  margin-top: 50px;
  padding: 0 60px;
  user-select: none;
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    text-transform: uppercase;
    color: #302874;
    h2 {
      &::after {
        margin: 0 30px;
        margin-bottom: 10px;
        display: inline-block;
        content: "";
        width: 18px;
        height: 18px;
        background-color: rgb(48, 40, 116);
      }
    }
    h3 {
      color: #4fbdf6;
    }
  }
  .title_info {
    text-align: center;
    font-size: 30px;
    background-image: linear-gradient(
      90deg,
      #302874,
      #4fbdf6 100%,
      #ffcb39 200%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.icon_line {
  font-size: 70px !important;
  color: #1d1d1f !important;
}
</style>