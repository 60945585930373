<template>
  <div class="traceability_body">
    <div
      class="traceability_itme"
      @click="show_toggle(i)"
      v-for="(item, i) in list_data"
      :key="i"
    >
      <div class="icon iconfont" :class="item.icon"></div>
      <h3>{{ item.title }}</h3>
      <p>{{ item.title_desc }}</p>
      <transition>
        <div
          class="popover"
          v-show="active_index == i"
          :class="[
            active_index == i
              ? 'animate__animated animate__fadeInDown'
              : 'animate__animated animate__fadeOutUp',
          ]"
          v-text="item.title_desc"
        ></div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListItem",
  data() {
    return {
      list_data: [],
      active_index: null,
      popover_isshow: false,
    };
  },
  methods: {
    load_list() {
      const { home } = require("@/assets/data/data.json");
      this.list_data = home.traceability_list;
    },
    show_toggle(i) {
      if (this.active_index == i) {
        this.active_index = null;
      } else {
        this.active_index = i;
      }
    },
  },
  created() {
    this.load_list();
  },
};
</script>

<style lang="less" scoped>
.traceability_body {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 30px;
  width: 100%;
}
.traceability_itme {
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 48%;
  margin-bottom: 40px;
  font-size: 25px;
  padding: 0 20px;
  border-radius: 10px;
  // border: 2px solid #424245;
  h3 {
    font-size: 35px;
    color: #1d1d1f;
  }
  p {
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
    color: #1d1d1f;
  }
  .icon {
    font-size: 100px !important;
    color: #312974;
  }
  .popover {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 28px;
    padding: 5px;
    border-radius: 10px;
    background-color: rgb(242, 244, 247);
  }
}
</style>